import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ColorConfig, ThemeColorService, ThemeMainColorType } from '@shared/feature/themes';

@Component({
  selector: 'pma-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, LoadingBarModule],
})
export class AppComponent {
  accentColor: ColorConfig = null!;

  constructor(themeColorService: ThemeColorService) {
    this.accentColor = themeColorService.getColorByVariant(ThemeMainColorType.accentColor, '500');
  }
}
