import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/feature/common';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  { path: 'rapportage', loadChildren: () => import('./reports/routes').then((m) => m.ROUTES) },
  { path: 'report', loadChildren: () => import('./reports/routes').then((m) => m.ROUTES) },
  { path: 'portal', loadChildren: () => import('./portal/routes').then((m) => m.ROUTES) },
  { path: 'auth', loadChildren: () => import('@shared/feature/auth').then((m) => m.ROUTES), data: { title: 'Mesis Portaal' } },
  { path: '**', component: PageNotFoundComponent },
];
